<template>
  <div
    class="select-component"
    :class="{
      filled: optionsSelected.length,
      hovered: isHovered,
      focused: isFocused,
      error: error || selectError,
      'is-small': small,
      'is-active': isDropdownOpen,
    }"
    @click="isDropdownOpen = !disabled"
    @mouseleave="isDropdownOpen = false"
  >
    <label v-if="showLabel" class="select-label"> {{ label }} </label>
    <div class="dropdown-trigger">
      <button
        class="button trigger-button is-fullwidth"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        :disabled="disabled"
        @mouseover="() => (isHovered = true)"
        @mouseleave="() => (isHovered = false)"
        @click.prevent
      >
        <span class="text" :class="{ filled: optionsSelected.length }">
          {{
            optionsSelected.length
              ? printedOptionsSelectedName(optionsSelected)
              : placeholder
          }}
        </span>
        <span class="icon is-small">
          <ExpandMore />
        </span>
      </button>

      <span v-if="showMessage || selectError" class="info">
        {{ message || selectErrorMsg }}
      </span>
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-content">
        <div class="filter-content">
          <AppInput
            type="text"
            placeholder="Digite"
            small
            label="Filtro"
            is-synchronized
            @input="(v) => (query = v)"
          />
        </div>

        <template v-for="(option, key) in standardOptions" :key="key">
          <label v-show="checkVisibility(option.label)" class="dropdown-item">
            <label class="checkbox">
              <input
                v-model="optionsSelected"
                type="checkbox"
                :value="option.value"
                :name="name"
                :disabled="option.disabled"
              />
              <span>{{ option.label }}</span>
            </label>
          </label>
        </template>
      </div>
    </div>
    <input
      v-model="inputHandle"
      class="input-handle"
      :required="required"
      @focus="selectErrorCallback"
    />
  </div>
</template>

<script>
import ExpandMore from "icons/cached/expand_more.svg";
import AppInput from "components/ui/AppInput.vue";

import { computed, ref, watch, watchEffect } from "vue";

export default {
  components: {
    ExpandMore,
    AppInput,
  },
  props: {
    message: {
      type: String,
      default: "Campo obrigatório",
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: String,
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Selecione",
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showMessage: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    valuesToShow: {
      type: Number,
      default: 1,
    },
    disabledValues: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const isFocused = ref(false);
    const isHovered = ref(false);
    const isDropdownOpen = ref(false);
    const inputHandle = ref("");
    const query = ref("");

    let standardOptions = computed(() => {
      if (props.options.length == 0) return props.options;

      if (
        typeof props.options[0] != "string" &&
        !(props.options[0] instanceof String)
      )
        return props.options;

      let currentOptions = props.options.map((option) => {
        return {
          value: option.toString(),
          label: option.toString(),
          disabled:
            props.disabledValues.includes(option.toString()) &&
            !optionsSelected.value.includes(option.toString()),
        };
      });

      return currentOptions;
    });

    const checkVisibility = (label) => {
      return label.toLowerCase().includes(query.value.toLowerCase());
    };

    const optionsSelected = ref(props.value);

    const printedOptionsSelectedName = (options) => {
      const optionsToShow = options.slice(0, props.valuesToShow);
      const moreOptions = options.length - props.valuesToShow;

      return optionsToShow
        .map((option) => {
          return standardOptions.value?.find((o) => o.value == option)?.label;
        })
        .join(", ")
        .concat(moreOptions > 0 ? ` e + ${moreOptions}` : "");
    };

    watchEffect(() => {
      optionsSelected.value = props.value;
    });

    watch(optionsSelected, (value) => {
      selectErrorCallback();
      emit("change", value);
      inputHandle.value = value;
    });

    const selectError = ref(false);
    const selectErrorMsg = ref("");

    const selectErrorCallback = () => {
      if (props.required) {
        if (optionsSelected.value.length > 0) {
          selectError.value = false;
          selectErrorMsg.value = "";
        } else {
          selectError.value = true;
          selectErrorMsg.value = "Campo obrigatório";
        }
      }
    };

    return {
      isFocused,
      isHovered,
      optionsSelected,
      printedOptionsSelectedName,
      isDropdownOpen,
      selectErrorCallback,
      selectError,
      selectErrorMsg,
      inputHandle,
      checkVisibility,
      standardOptions,
      query,
    };
  },
};
</script>
